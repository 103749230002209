//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "OddsSingleLine",
  props: {
    // [market_id, bet_type, handicap, [stake_number, handicapDisplayName, odds, {"up" | "down"}] ...],
    odds: Array
  },
  data () {
    return {
      indicators: {}
    }
  },
  mounted () {

  },
  computed: {
    activeOdds: function () {
      return this.odds.slice(3).filter(o => o != null && o.length > 0);
    }
  },
  watch: {
    odds: function (newVal, oldVal) {
      if (oldVal) {
        for (var i = 3; i < newVal.length; i++) {
          this.indicators[`${newVal[0]}-${newVal[i][0]}`] = newVal[i][3] == 0 ? '' : newVal[i][3] > 0 ? 'up' : 'down'
        }

        // var timer = 20
        let clock = window.setInterval(() => {
          window.clearInterval(clock)
          this.indicators = {}
        }, 5000);
      }
    }
  },
  methods: {
    is1x2: function (typeId) {
      return typeId === 11005 || typeId === 11008;
    }
  }
};
