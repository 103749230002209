//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Odds from "../components/Odds"

const months = ['month.jan', 'month.feb', 'month.mar', 'month.apr', 'month.may', 'month.jun', 'month.jul', 'month.aug', 'month.sep', 'month.oct', 'month.nov', 'month.dec']
const weeks = ['week.sun', 'week.mon', 'week.tues', 'week.web', 'week.thur', 'week.fri', 'week.sat']
const intervals = { 0: 30000, 1: 10000, 2: 30000, 3: 60000 }

export default {
  name: 'Steps',
  components: {
    Odds
  },
  data () {
    return {
      sports: [],
      timestamp: 0,
      sportsbook: ['', 'live', 'today', 'early'],
      betTypes: '11002,11004,11005,11001,11003,11008',
      oddsTypes: [
        { code: "DEC", name: "Decimal Odds" },
        { code: "MY", name: "Malay Odds" },
        { code: "HK", name: "Hong Kong Odds" },
        { code: "INDO", name: "Indonesia Odds" }
      ],
      selectedOddsType: 'Step',
      selectedTime: this.$route.params.sportsbook,
      loading: false,
      clock: null,
      checkedAllLeauge: false,
      checkedLeagues: [],
      sorts: [
        { name: 'league', value: 1 },
        { name: 'time', value: 2 }
      ],
      selectedSort: this.$store.state.profiles.sort,
      favorites: this.$store.state.profiles.favorites
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.sports = []
    this.timestamp = 0
    this.loadOdds(to.params)
    next()
  },
  mounted () {
    this.loadOdds(this.$route.params)

    this.$watch('selectedOddsType', (newVal, oldVal) => {
      if (oldVal && newVal !== oldVal) {
        this.$store.commit('profiles', { oddsType: newVal })
        this.sports = []
        this.timestamp = 0
        this.loadOdds(Object.assign(this.$route.params, { timestamp: 0, oddsType: newVal }))
      }
    },
      { immediate: true })

    this.$watch('selectedSort', (newVal, oldVal) => {
      if (oldVal) {
        this.$store.commit('profiles', { sort: newVal })
        this.sports = []
        this.timestamp = 0
        this.loadOdds(Object.assign(this.$route.params, { timestamp: 0, sort: newVal }))
      }
    })

    this.$watch('$i18n.locale', (newVal, oldVal) => {
      if (oldVal) {
        this.sports = []
        this.timestamp = 0
        this.loadOdds(this.$route.params)
      }
    },
      { immediate: true })
  },
  destroyed () {
    console.log("shutdown")
    if (this.clock) {
      window.clearInterval(this.clock)
    }
  },
  computed: {
    title: function () {
      return this.sportsbook[this.$route.params.sportsbook]
    },
    loggedOn: function () {
      return this.$store.state.loggedOn;
    },
    params: function () {
      return this.$route.params;
    },
    dates: function () {
      var result = []
      var dt = new Date()
      for (var i = 1; i <= 7; i++) {
        dt.setDate(dt.getDate() + 1)
        const name = `${dt.getDate()}/${this.$t(months[dt.getMonth()])} (${this.$t(weeks[dt.getDay()])})`
        const time = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) / 1000
        result.push({ name, time })
      }
      return [{ name: "All", time: 3 }, ...result]
    },
    leagues: function () {
      let keys = {}
      return this.sports.flatMap(type => type.slice(5)).map(league => [league[0], league[1]])
        .filter(val => (keys[val[0]]) ? false : keys[val[0]] = true)
    }
  },
  watch: {
    checkedAllLeauge: function (newVal) {
      this.checkedLeagues = newVal ? this.leagues : []
    }
  },
  methods: {
    hasDraw: function (groups) {
      return groups.filter(m => m.length > 0 && (m[1] === 11005 || m[1] === 11008)).length > 0
    },
    isValid: function (model) {
      return 'sportsbook' in model && 'sportId' in model
    },
    loadOdds: function (model) {

      if (this.isValid(model)) {
        this.loading = true

        this.$store.dispatch('loadOdds', Object.assign(model, { timestamp: this.timestamp, oddsType: this.selectedOddsType, betTypes: this.betTypes }))
          .then(data => {
            if (data.sports.length > 0) {
              if (this.sports.length === 0 || this.timestamp === 0) {
                this.sports = data.sports
              }
              else {
                console.log(data)

                data.sports.forEach(sport => {
                  var i = this.sports.findIndex(e => e[0] === sport[0])
                  if (i === -1) {
                    this.sports.unshift(sport)
                  } else {
                    var leagues = sport.slice(5).map(league => {
                      var changedMatches = league.slice(4)
                      var oldMatches = this.sports[i].filter((v, i) => i > 4 && v[0] === league[0])
                        .flatMap(e => e)
                        .filter((v, i) => i > 3 && changedMatches.findIndex(m => m[0] == v[0]) === -1)
                      var newMatches = changedMatches.filter(m => m.length > 16)
                        .concat(oldMatches).sort((m1, m2) => m1[14] - m2[14])
                      var changedLeague = league.slice(0, 4).concat(newMatches)

                      return changedLeague;
                    })

                    var newSport = sport.slice(0, 5).concat(
                      leagues.filter(l => l.length > 4).concat(this.sports[i].filter((v, i) => i > 4 && leagues.findIndex(l => l[0] === v[0]) == -1))
                        .sort((l1, l2) => l1[3] - l2[3])
                    )

                    this.sports.splice(i, 1, newSport)
                  }
                })
              }
            }

            this.timestamp = data.timestamp
            this.loading = false

            // Start clock
            if (!this.clock) {
              let sb = this.sports.length > 0 ? Math.min(...this.sports.map(m => m[0])) : 0
              let timeout = intervals[sb]
              console.log(`Starting clock and reload odds at ${timeout / 1000}s after.`)
              this.clock = window.setInterval(() => {
                console.log(`Fetch odds at ${this.timestamp}.`)
                this.loadOdds(Object.assign(this.$route.params, { timestamp: this.timestamp }))
              }, timeout)
            }
          })
      }
      else if ('sportsbook' in model && 'sports' in model) {
        this.loading = true
        this.$store.dispatch('loadOddsWithSports', Object.assign(model, { oddsType: this.selectedOddsType }))
          .then(data => {
            this.sports = data.sports
            this.timestamp = data.timestamp
            this.loading = false
          })
      }
    },
    allFavorite: function (events) {
      return events.filter(e => this.favorites.indexOf(e) == -1).length === 0
    },
    setFavorites: function (events, all = false) {
      let adds = [], removes = []
      if (all) {
        if (events.filter(e => this.favorites.indexOf(e[0]) > -1).length == events.length) {
          removes = events
          events.forEach(e => {
            let i = this.favorites.indexOf(e[0])
            if (i > -1) {
              this.favorites.splice(i, 1)
            }
          })
        } else {
          adds = events
          events.forEach(e => {
            let i = this.favorites.indexOf(e[0])
            if (i == -1) {
              this.favorites.push(e[0])
            }
          })
        }
      } else {
        events.forEach(e => {
          let i = this.favorites.indexOf(e[0])
          if (i == -1) {
            this.favorites.push(e[0])
            adds.push(e)
          } else {
            this.favorites.splice(i, 1)
            removes.push(e)
          }
        })
      }

      if (adds.length) {
        this.$store.dispatch('addFavorites', adds)
      }

      if (removes.length) {
        this.$store.dispatch('removeFavorites', removes)
      }
    },
    betting: function (odds) {
      if (this.loggedOn) {
        this.$store.commit('active', 'betslip')
        this.$store.dispatch('checkBetting', {
          marketId: odds[0],
          handicap: odds[2],
          stakeNumber: odds[3],
          odds: odds[5],
          oddsType: 'step'
        })
      }
    },
    dateChanges: function (date) {
      this.selectedTime = date.time
      this.sports = []
      this.loadOdds(Object.assign(this.$route.params, { sportsbook: date.time, timestamp: 0 }))
    },
    isCheckedLeague: function (id) {
      return this.checkedLeagues.length === 0 || this.checkedLeagues.filter(val => val[0] === id).length > 0
    }
  }
}
